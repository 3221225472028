import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { NOTICE_TYPE } from '@core/constants';
import { FinancialInstitution, LoaderState } from '@store/core/core.interface';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { PbbNoticeComponent } from '../pbb-notice/pbb-notice.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { PbbImgErrorDirective } from '@core/directives/pbb-img-error.directive';
import { Base64Pipe } from '@core/pipes/base64.pipe';

@Component({
  selector: 'pbb-fi-selection',
  templateUrl: './fi-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    PbbNoticeComponent,
    MatListModule,
    NgFor,
    MatButtonModule,
    PbbImgErrorDirective,
    NgxSkeletonLoaderModule,
    Base64Pipe,
    TranslateModule,
    AsyncPipe,
  ],
})
export class FiSelectionComponent implements OnChanges {
  @Output() fiSelected: EventEmitter<FinancialInstitution> = new EventEmitter();
  @Input() financialInstitutions!: LoaderState<FinancialInstitution[]>;
  unhealthyFI: boolean = false;
  noticeType = NOTICE_TYPE.WARNING;
  ngOnChanges(): void {
    this.unhealthyFI = !!this.financialInstitutions.data?.some((fi: FinancialInstitution) => !fi.health_status);
  }
}
