import { LoaderState, FinancialInstitution } from '@store/core/core.interface';
import { selectFinancialInstitutions } from '@store/core/core.selectors';
import { Store } from '@ngrx/store';
import { UntilDestroy } from '@core/classes/untilDestroy.class';
import { Observable, filter, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { WindowService } from '@core/services/window.service';
import { Directive, Inject } from '@angular/core';
import { selectConsentId } from '@store/checkout/checkout.selectors';
import { selectFI } from '@store/core/core.actions';
import { ENVCONFIG } from '@core/tokens/configs';
import { ENV_CONFIG } from '@core/interfaces/configs';
import { LoggerService } from '@core/services/logger.service';
import { SNAME } from '@core/constants/storage';

@Directive()
export abstract class FiSelectionClass extends UntilDestroy {
  financialInstitutions$: Observable<LoaderState<FinancialInstitution[]>> = this.store
    .select(selectFinancialInstitutions)
    .pipe(filter((finacialInstitutions) => !!finacialInstitutions));

  standalone: boolean = false;
  redirectUrl: string = '';
  rus = true;
  authentication = true;
  constructor(
    public store: Store,
    public dialog: MatDialog,
    public window: Window,
    public merchantWindowService: WindowService,
    @Inject(ENVCONFIG) private readonly envConfig: ENV_CONFIG
  ) {
    super();
  }

  fiSelected(selectedFI: FinancialInstitution, userProfileRef?: string, email?: string) {
    const selectFIPayload: {
      selectedFI: any;
      redirectUrl: string;
      userProfileRef?: string;
      email?: string;
      rus?: boolean;
    } = {
      selectedFI,
      redirectUrl: this.redirectUrl,
    };
    if (userProfileRef && email) {
      new LoggerService(this.envConfig).error(
        'AUTH',
        'userProfileRef and email are exclusive and should not be passed together.'
      );
    }
    if (userProfileRef) {
      selectFIPayload.userProfileRef = userProfileRef;
    }
    if (email) {
      selectFIPayload.email = email;
    }

    if (this.authentication) {
      sessionStorage.setItem(SNAME.AUTH_METHOD, selectedFI.id || '');
    }
    selectFIPayload.rus = this.rus;

    if (this.standalone) {
      this.store.dispatch(selectFI(selectFIPayload));
    } else {
      this.store
        .select(selectConsentId)
        .pipe(take(1))
        .subscribe((consentId) => {
          this.store.dispatch(selectFI({ ...selectFIPayload, consentId }));
        });
    }
  }
}
