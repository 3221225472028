<div class="fi-list mt-24 mb-32">
  <pbb-notice
    *ngIf="unhealthyFI"
    [title]="'COMMON.FI.DISABLE' | translate"
    id="fi_disabled"
    [type]="noticeType"
  ></pbb-notice>
  <mat-list role="list">
    <ng-container *ngFor="let institution of financialInstitutions.data">
      <mat-list-item role="listitem" *ngIf="institution.priority">
        <button
          [attr.id]="'main_checkout_fi-' + institution.display_name"
          class="btn-fi"
          mat-stroked-button
          (click)="fiSelected.next(institution)"
          [disabled]="!institution.health_status"
          [attr.aria-label]="institution.display_name"
        >
          <img pbbImgError="assets/images/bank.png" alt="{{institution.display_name}}" [src]="institution.logo | base64 | async" />
          <span class="mat-body-2 text-dark-charcoal">
            {{ institution.display_name || '' }}
          </span>
        </button>
      </mat-list-item>
    </ng-container>
  </mat-list>
</div>
<ngx-skeleton-loader *ngIf="financialInstitutions.isLoading" count="5" appearance="line"></ngx-skeleton-loader>
<div *ngIf="financialInstitutions.error">
  {{ 'COMMON.ERROR.TRY_AGAIN' | translate }}
</div>
