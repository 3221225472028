import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { redirectTOFILogin } from '@store/core/core.actions';
import { FinancialInstitution } from '@store/core/core.interface';
import { selectSelectedFI } from '@store/core/core.selectors';
import { Observable, tap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { InfoListComponent } from '../info-list/info-list.component';
import { MatDividerModule } from '@angular/material/divider';
import { NgIf, AsyncPipe } from '@angular/common';
import { LetDirective } from '@ngrx/component';
import { PbbImgErrorDirective } from '@core/directives/pbb-img-error.directive';
import { Base64Pipe } from '@core/pipes/base64.pipe';
import { PbbSvgIconComponent } from '../pbb-svg-icon/pbb-svg-icon.component';

@Component({
  selector: 'pbb-fi-redirect',
  templateUrl: './fi-redirect.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LetDirective,
    NgIf,
    PbbSvgIconComponent,
    MatDividerModule,
    PbbImgErrorDirective,
    InfoListComponent,
    MatButtonModule,
    Base64Pipe,
    TranslateModule,
    AsyncPipe,
  ],
})
export class FiRedirectComponent implements OnInit {
  title = 'AUTH_REDIRECT_TITLE';
  subtitle = '';
  selectedFI$: Observable<FinancialInstitution> = this.store.select(selectSelectedFI).pipe(
    tap((selectedFI) => {
      if (!selectedFI.display_name) {
        this.cancel();
      }
    })
  );
  infoList = [
    {
      title: 'AUTH_REDIRECT.LIST_ITEM1.TITLE',
      subtitle: 'AUTH_REDIRECT.LIST_ITEM1.SUBTITLE',
      icon: 'admin_panel_settings',
    },
    {
      title: 'AUTH_REDIRECT.LIST_ITEM2.TITLE',
      subtitle: 'AUTH_REDIRECT.LIST_ITEM2.SUBTITLE',
      icon: 'credit_card',
    },
    {
      title: 'AUTH_REDIRECT.LIST_ITEM3.TITLE',
      subtitle: 'AUTH_REDIRECT.LIST_ITEM3.SUBTITLE',
      icon: 'account_balance',
    },
  ];
  authURL!: string;
  stepUp = false;
  constructor(private store: Store, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.authURL = this.activatedRoute.snapshot.queryParams['authURL'];
    this.stepUp = !!this.activatedRoute.snapshot.queryParams['stepUp'];
    if (!this.authURL) {
      this.cancel();
    }
    if (this.stepUp) {
      this.title = 'AUTH_REDIRECT_STEPUP_TITLE';
      this.subtitle = 'AUTH_REDIRECT_STEPUP_SUBTITLE';
    }
  }

  continue() {
    this.store.dispatch(
      redirectTOFILogin({
        authURL: this.authURL,
      })
    );
  }

  cancel() {
    this.router.navigate(['..']);
  }
}
